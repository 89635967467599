import { default as aboutcggDDldG7iMeta } from "/vercel/path0/pages/about.vue?macro=true";
import { default as index88DkDLkmVSMeta } from "/vercel/path0/pages/admin/blend-directions/create/index.vue?macro=true";
import { default as indexG7R2Mya2P4Meta } from "/vercel/path0/pages/admin/index.vue?macro=true";
import { default as indexb39xQJYDHPMeta } from "/vercel/path0/pages/admin/phonemes/create/index.vue?macro=true";
import { default as indexadLAHHFyYCMeta } from "/vercel/path0/pages/admin/positions/create/index.vue?macro=true";
import { default as indexzNu0TTIMnJMeta } from "/vercel/path0/pages/admin/roles/create/index.vue?macro=true";
import { default as indexwfNRzqnl9FMeta } from "/vercel/path0/pages/admin/target-sounds/create/index.vue?macro=true";
import { default as indexT0wPu5xB4zMeta } from "/vercel/path0/pages/admin/target-words/create/index.vue?macro=true";
import { default as indexiCutx5wIpDMeta } from "/vercel/path0/pages/admin/target-words/manage/index.vue?macro=true";
import { default as indexi9bLCzx6PCMeta } from "/vercel/path0/pages/admin/target-words/view/index.vue?macro=true";
import { default as artic_45sidekickKKNEOYd44DMeta } from "/vercel/path0/pages/artic-sidekick.vue?macro=true";
import { default as auth_45errorKcefDh3MSyMeta } from "/vercel/path0/pages/auth-error.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as privacykJ3SQnmrN4Meta } from "/vercel/path0/pages/privacy.vue?macro=true";
import { default as indexoQXEWKSyxYMeta } from "/vercel/path0/pages/profile/edit/index.vue?macro=true";
import { default as indexzDxOJlC9CpMeta } from "/vercel/path0/pages/students/add/index.vue?macro=true";
import { default as indexgabsFWvsCkMeta } from "/vercel/path0/pages/students/index.vue?macro=true";
import { default as terms_45of_45serviceSmXvkgSRNCMeta } from "/vercel/path0/pages/terms-of-service.vue?macro=true";
export default [
  {
    name: aboutcggDDldG7iMeta?.name ?? "about",
    path: aboutcggDDldG7iMeta?.path ?? "/about",
    meta: aboutcggDDldG7iMeta || {},
    alias: aboutcggDDldG7iMeta?.alias || [],
    redirect: aboutcggDDldG7iMeta?.redirect,
    component: () => import("/vercel/path0/pages/about.vue").then(m => m.default || m)
  },
  {
    name: index88DkDLkmVSMeta?.name ?? "admin-blend-directions-create",
    path: index88DkDLkmVSMeta?.path ?? "/admin/blend-directions/create",
    meta: index88DkDLkmVSMeta || {},
    alias: index88DkDLkmVSMeta?.alias || [],
    redirect: index88DkDLkmVSMeta?.redirect,
    component: () => import("/vercel/path0/pages/admin/blend-directions/create/index.vue").then(m => m.default || m)
  },
  {
    name: indexG7R2Mya2P4Meta?.name ?? "admin",
    path: indexG7R2Mya2P4Meta?.path ?? "/admin",
    meta: indexG7R2Mya2P4Meta || {},
    alias: indexG7R2Mya2P4Meta?.alias || [],
    redirect: indexG7R2Mya2P4Meta?.redirect,
    component: () => import("/vercel/path0/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: indexb39xQJYDHPMeta?.name ?? "admin-phonemes-create",
    path: indexb39xQJYDHPMeta?.path ?? "/admin/phonemes/create",
    meta: indexb39xQJYDHPMeta || {},
    alias: indexb39xQJYDHPMeta?.alias || [],
    redirect: indexb39xQJYDHPMeta?.redirect,
    component: () => import("/vercel/path0/pages/admin/phonemes/create/index.vue").then(m => m.default || m)
  },
  {
    name: indexadLAHHFyYCMeta?.name ?? "admin-positions-create",
    path: indexadLAHHFyYCMeta?.path ?? "/admin/positions/create",
    meta: indexadLAHHFyYCMeta || {},
    alias: indexadLAHHFyYCMeta?.alias || [],
    redirect: indexadLAHHFyYCMeta?.redirect,
    component: () => import("/vercel/path0/pages/admin/positions/create/index.vue").then(m => m.default || m)
  },
  {
    name: indexzNu0TTIMnJMeta?.name ?? "admin-roles-create",
    path: indexzNu0TTIMnJMeta?.path ?? "/admin/roles/create",
    meta: indexzNu0TTIMnJMeta || {},
    alias: indexzNu0TTIMnJMeta?.alias || [],
    redirect: indexzNu0TTIMnJMeta?.redirect,
    component: () => import("/vercel/path0/pages/admin/roles/create/index.vue").then(m => m.default || m)
  },
  {
    name: indexwfNRzqnl9FMeta?.name ?? "admin-target-sounds-create",
    path: indexwfNRzqnl9FMeta?.path ?? "/admin/target-sounds/create",
    meta: indexwfNRzqnl9FMeta || {},
    alias: indexwfNRzqnl9FMeta?.alias || [],
    redirect: indexwfNRzqnl9FMeta?.redirect,
    component: () => import("/vercel/path0/pages/admin/target-sounds/create/index.vue").then(m => m.default || m)
  },
  {
    name: indexT0wPu5xB4zMeta?.name ?? "admin-target-words-create",
    path: indexT0wPu5xB4zMeta?.path ?? "/admin/target-words/create",
    meta: indexT0wPu5xB4zMeta || {},
    alias: indexT0wPu5xB4zMeta?.alias || [],
    redirect: indexT0wPu5xB4zMeta?.redirect,
    component: () => import("/vercel/path0/pages/admin/target-words/create/index.vue").then(m => m.default || m)
  },
  {
    name: indexiCutx5wIpDMeta?.name ?? "admin-target-words-manage",
    path: indexiCutx5wIpDMeta?.path ?? "/admin/target-words/manage",
    meta: indexiCutx5wIpDMeta || {},
    alias: indexiCutx5wIpDMeta?.alias || [],
    redirect: indexiCutx5wIpDMeta?.redirect,
    component: () => import("/vercel/path0/pages/admin/target-words/manage/index.vue").then(m => m.default || m)
  },
  {
    name: indexi9bLCzx6PCMeta?.name ?? "admin-target-words-view",
    path: indexi9bLCzx6PCMeta?.path ?? "/admin/target-words/view",
    meta: indexi9bLCzx6PCMeta || {},
    alias: indexi9bLCzx6PCMeta?.alias || [],
    redirect: indexi9bLCzx6PCMeta?.redirect,
    component: () => import("/vercel/path0/pages/admin/target-words/view/index.vue").then(m => m.default || m)
  },
  {
    name: artic_45sidekickKKNEOYd44DMeta?.name ?? "artic-sidekick",
    path: artic_45sidekickKKNEOYd44DMeta?.path ?? "/artic-sidekick",
    meta: artic_45sidekickKKNEOYd44DMeta || {},
    alias: artic_45sidekickKKNEOYd44DMeta?.alias || [],
    redirect: artic_45sidekickKKNEOYd44DMeta?.redirect,
    component: () => import("/vercel/path0/pages/artic-sidekick.vue").then(m => m.default || m)
  },
  {
    name: auth_45errorKcefDh3MSyMeta?.name ?? "auth-error",
    path: auth_45errorKcefDh3MSyMeta?.path ?? "/auth-error",
    meta: auth_45errorKcefDh3MSyMeta || {},
    alias: auth_45errorKcefDh3MSyMeta?.alias || [],
    redirect: auth_45errorKcefDh3MSyMeta?.redirect,
    component: () => import("/vercel/path0/pages/auth-error.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: privacykJ3SQnmrN4Meta?.name ?? "privacy",
    path: privacykJ3SQnmrN4Meta?.path ?? "/privacy",
    meta: privacykJ3SQnmrN4Meta || {},
    alias: privacykJ3SQnmrN4Meta?.alias || [],
    redirect: privacykJ3SQnmrN4Meta?.redirect,
    component: () => import("/vercel/path0/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: indexoQXEWKSyxYMeta?.name ?? "profile-edit",
    path: indexoQXEWKSyxYMeta?.path ?? "/profile/edit",
    meta: indexoQXEWKSyxYMeta || {},
    alias: indexoQXEWKSyxYMeta?.alias || [],
    redirect: indexoQXEWKSyxYMeta?.redirect,
    component: () => import("/vercel/path0/pages/profile/edit/index.vue").then(m => m.default || m)
  },
  {
    name: indexzDxOJlC9CpMeta?.name ?? "students-add",
    path: indexzDxOJlC9CpMeta?.path ?? "/students/add",
    meta: indexzDxOJlC9CpMeta || {},
    alias: indexzDxOJlC9CpMeta?.alias || [],
    redirect: indexzDxOJlC9CpMeta?.redirect,
    component: () => import("/vercel/path0/pages/students/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexgabsFWvsCkMeta?.name ?? "students",
    path: indexgabsFWvsCkMeta?.path ?? "/students",
    meta: indexgabsFWvsCkMeta || {},
    alias: indexgabsFWvsCkMeta?.alias || [],
    redirect: indexgabsFWvsCkMeta?.redirect,
    component: () => import("/vercel/path0/pages/students/index.vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45serviceSmXvkgSRNCMeta?.name ?? "terms-of-service",
    path: terms_45of_45serviceSmXvkgSRNCMeta?.path ?? "/terms-of-service",
    meta: terms_45of_45serviceSmXvkgSRNCMeta || {},
    alias: terms_45of_45serviceSmXvkgSRNCMeta?.alias || [],
    redirect: terms_45of_45serviceSmXvkgSRNCMeta?.redirect,
    component: () => import("/vercel/path0/pages/terms-of-service.vue").then(m => m.default || m)
  }
]