<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script lang="ts" setup>
useHead({
  htmlAttrs: {
    lang: "en",
  },
});

useServerHead({
  link: [
    {
      href: "/apple-touch-icon.png",
      rel: "apple-touch-icon",
      sizes: "180x180",
    },
    {
      href: "/favicon.svg",
      rel: "icon",
      type: "image/svg+xml",
    },
    {
      href: "/favicon-32x32.png",
      rel: "icon",
      sizes: "32x32",
      type: "image/png",
    },
    {
      href: "/favicon-16x16.png",
      rel: "icon",
      sizes: "16x16",
      type: "image/png",
    },
    {
      href: "/site.webmanifest",
      rel: "manifest",
    },
    {
      href: "/safari-pinned-tab.svg",
      rel: "mask-icon",
    },
  ],
  meta: [
    {
      name: "msapplication-TileColor",
      content: "#2d89ef",
    },
    {
      name: "theme-color",
      content: "#ffffff",
    },
  ],
});

useServerSeoMeta({
  title: "ArticSidekick",
  description: "A tool for speech and language pathologists",
  ogDescription: "A tool for speech and language pathologists",
  ogTitle: "ArticSidekick",
});
</script>
